import { ArrowForwardIcon, LockIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Link, Spinner } from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useState } from "react";
import { formatEther } from "viem";
import {
  useAccount,
  useDisconnect,
  useReadContract,
  useWriteContract,
} from "wagmi";
import { AIRDROP_ADDRESS, FOCUS_ADDRESS } from "../../../../config/blockchain";
import { useCountdown } from "../../../../hooks/useCountdown";
import { CenterContainer } from "../../../shared/CenterContainer/centerContainer";
import { AirdropABI } from "./AirdropABI";

import Fireworks from "react-canvas-confetti/dist/presets/explosion";

function Example({init} : {init: () => void}) {
  return <Fireworks onInit={init} autorun={{speed: 0.0001}} />;
}

export default Example;

export const AirdropWrapper = () => {
  const { minutes, hours, days, seconds, passed } = useCountdown(
    2024,
    4,
    14,
    18,
  );

  const [loading, setLoading] = useState<boolean>(true);

  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const [counter, setCounter] = useState<number>(0);

  const [airdropAmount, setAirdropAmount] = useState<number>(0);
  const [isElgible, setIsElgible] = useState<boolean>(false);
  const [alreadyClaimed, setAlreadyClaimed] = useState<boolean>(false);

  const [checkingElgible, setCheckingElgible] = useState<boolean>(true);

  const {
    data: hash,
    isPending,
    isSuccess,
    writeContract,
  } = useWriteContract();

  const { open } = useWeb3Modal();

  const queryAmount = useReadContract({
    abi: AirdropABI,
    address: AIRDROP_ADDRESS,
    functionName: "claimableAmount",
    args: [address],
  }) as any;

  const queryClaimed = useReadContract({
    abi: AirdropABI,
    address: AIRDROP_ADDRESS,
    functionName: "claimed",
    args: [address],
  }) as any;

  useEffect(() => {
    console.log("Prod ready.")
    console.log("Airdrop", AIRDROP_ADDRESS)
    console.log("Token", FOCUS_ADDRESS)

  }, [])

  useEffect(() => {
    if (queryClaimed.isFetching == false && queryAmount.isFetching == false) {
      setCheckingElgible(false);
      setAirdropAmount(queryAmount.data);

      setIsElgible(queryAmount.data > 0);

      if (queryClaimed.data == true) {
        setAlreadyClaimed(true);
        setIsElgible(true);
      }
      
    }
  }, [queryClaimed, queryAmount]);

  useEffect(() => {
    if (seconds != 0) {
      setLoading(false);
    }
  }, [seconds]);

  const handleClaim = () => {
    writeContract({
      abi: AirdropABI,
      address: AIRDROP_ADDRESS,
      functionName: "claim",
    });
  };


  useEffect(() => {
    if(counter > 20) {
      setForcePassed(true)
    }
  }, [counter])

  const [forcePassed, setForcePassed] = useState<boolean>(false);

  const [showConfetti, setShowConfetti] = useState<boolean>(true);


  return (
    <CenterContainer>
      {address && isElgible && showConfetti && <Example init={() => {}}/>}
      <Flex
        flexDir="column"
        textAlign="center"
        minH="800px"
        justify="center"
        align="center"
        gap="40px"
      >
        {!isConnected && (
          <Flex flexDir="column" gap="40px" align="center">
            <Flex
              fontSize={{ base: "44px", md: "54px" }}
              maxW="650px"
              flexDir="column"
              gap="24px"
            >
              <Box lineHeight="100%">Focus Token Airdrop</Box>
              <Box fontSize="24px" maxW="600px" color="text.500">
                {passed ? (
                  <>
                    <Box display="inline" color="brand.500" >
                      $FOCUS airdrop is live.
                    </Box>{" "}
                    Connect with your wallet provider and check if you can claim rewards!
                  </>
                ) : (
                  <>
                    <Box display="inline" color="brand.500" onClick={() => setCounter((prev) => prev + 1)}>
                      $FOCUS
                    </Box>{" "}
                    is resuming soon. Stay tuned and follow our
                    official &quot;X&quot; channel for updates.
                  </>
                )}
              </Box>
            </Flex>
            <Flex align="center" flexDir="column" h="120px">
              {(!passed && !forcePassed) ? (
                <>
                  {loading ? (
                    <Flex align="center" gap="16px">
                      {" "}
                      <Spinner />
                      <Box fontSize="24px">Loading...</Box>
                    </Flex>
                  ) : (
                    <Flex
                      fontSize={{ base: "40px", md: "50px" }}
                      align="center"
                    >
                      <Box w="70px">{days < 10 ? `0${days}` : days}</Box>
                      <Box color="brand.500">:</Box>
                      <Box w="70px">{hours < 10 ? `0${hours}` : hours}</Box>
                      <Box color="brand.500">:</Box>
                      <Box w="70px">
                        {minutes < 10 ? `0${minutes}` : minutes}
                      </Box>
                      <Box color="brand.500">:</Box>
                      <Box w="70px">
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </Box>
                    </Flex>
                  )}
                </>
              ) : (
                
               
                <Button variant="flare" onClick={() => open()}>
                  Connect Wallet
                </Button>
              )}

            </Flex>
          </Flex>
        )}
        {isConnected && address && (
          <Flex flexDir="column" align="center" textAlign="center" gap="16px">
            <Box fontSize="54px" lineHeight="100%">
              Connected wallet
            </Box>
            <Flex
              fontSize="24px"
              color="brand.500"
             
            >
              {address.slice(0, 8)}...{address.slice(-6)}
            </Flex>
            {checkingElgible && (
              <Flex gap="16px" mt="48px" fontSize="24px" align="center" justify="center">
                <Spinner boxSize="20px" />
                <Box>Checking airdrop...</Box>
              </Flex>
            )}
            {!checkingElgible && (
              <>
                {isElgible ? (
                  alreadyClaimed ? (
                    <Box fontSize='24px'>Already claimed your FOCUS.</Box>
                  ) : isSuccess ? (
                    <Flex flexDir="column" fontSize='24px'>Success. <Link display={"flex"} color='brand.500' target="_blank" gap="8px" alignItems="center" fontSize="20px" cursor='Pointer' href={`https://flare-explorer.flare.network/tx/${hash ?? ""}`}>View on Explorer <ArrowForwardIcon h="24px"/></Link></Flex>
                  ) : (
                    <Flex flexDir="column">
                      <Flex flexDir="column" fontSize="24px" my='24px'>
                        You can claim {formatEther(BigInt(airdropAmount))} FOCUS!
                      </Flex>
                      <Button onClick={handleClaim}>
                        {isPending ? <Spinner /> : "Claim Now!"}
                      </Button>
                    </Flex>
                  )
                ) : (
                  <Box>This account is not eligible for airdrop.</Box>
                )}
              </>
            )}
          </Flex>
        )}
              {isConnected && <Flex align="center" cursor="pointer" _hover={{textDecor: "underline"}} gap="8px" color="brand.500" fontSize={"14px"} onClick={() => disconnect()}>Disconnect. <LockIcon/></Flex>}

      </Flex>
    </CenterContainer>
  );
};

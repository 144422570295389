import { LockIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, Link, Spinner } from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import {
    BaseError,
    useAccount,
    useDisconnect,
    useReadContract,
    useWaitForTransactionReceipt,
    useWriteContract,
} from "wagmi";
import { FOCUS_ADDRESS, MINTER_ADDRESS } from "../../../../config/blockchain";
import { ERC20_ABI } from "../../airdrop/AirdropWrapper/ERC20Abi";
import { SaleABI } from "./SaleABI";

const MAX_NFT = 599;

const FIRST_ROUND_MAX = 30;
const SECOND_ROUND_MAX = 60;

export const MintWrapper = () => {
  const {
    data: hash,
    isPending,
    isSuccess,
    error,
    writeContract,
  } = useWriteContract();

  const {
    data: allowanceHash,
    isPending: isAllowancePending,
    isSuccess: isAllowanceSuccess,
    isError: isAllowanceError,
    writeContract: addAllowance,
  } = useWriteContract();

  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const { open } = useWeb3Modal();

  const [isElgible, setIsElgible] = useState<boolean>(false);

  const currentRoundQuery = useReadContract({
    abi: SaleABI,
    address: MINTER_ADDRESS,
    functionName: "getCurrentRound",
    args: [],
  }) as any;

  const boughtThisRoundQuery = useReadContract({
    abi: SaleABI,
    address: MINTER_ADDRESS,
    functionName: "boughtThisRound",
    args: [],
  }) as any;

  const userBoughtThisRoundQuery = useReadContract({
    abi: SaleABI,
    address: MINTER_ADDRESS,
    functionName: "userBoughtThisRound",
    args: [address],
  }) as any;

  const isWhitelisted = useReadContract({
    abi: SaleABI,
    address: MINTER_ADDRESS,
    functionName: "whitelist",
    args: [address],
  }) as any;

  const allowance = useReadContract({
    abi: ERC20_ABI,
    address: FOCUS_ADDRESS,
    functionName: "allowance",
    args: [address, MINTER_ADDRESS],
  }) as any;

  const started = useReadContract({
    abi: SaleABI,
    address: MINTER_ADDRESS,
    functionName: "started",
    args: [],
  }) as any;

  const handleAllowance = () => {
    if (!allowanceHash)
      addAllowance({
        address: FOCUS_ADDRESS,
        abi: ERC20_ABI,
        functionName: "approve",
        args: [MINTER_ADDRESS, ethers.parseEther("200000")],
      });
  };

  const handleBuy = () => {
    let functionName = "";

    let round = Number(currentRoundQuery.data);
    let value = 0;

    console.log(round);

    if (round === 1) {
      functionName = "buyFirstRound";
      value = 5000;
    } else if (round === 2) {
      functionName = "buySecondRound";
      value = 0;
    } else {
      functionName = "buyThirdRound";
      value = 10000;
    }

    writeContract({
      abi: SaleABI,
      address: MINTER_ADDRESS,
      functionName: functionName,
      value: ethers.parseEther(value.toString()),
    });
  };

  const [isAllowanceNeeded, setIsAllowanceNeeded] = useState<boolean>(false);

  const [passesMintedNow, setPassesMintedNow] = useState<number>(0);

  const [mintingEnded, setMintingEnded] = useState<boolean>(false);

  useEffect(() => {
    if (
      Number(currentRoundQuery.data) == 2 &&
      allowance.data < ethers.parseEther("200000")
    ) {
      setIsAllowanceNeeded(true);
    } else {
      setIsAllowanceNeeded(false);
    }
  }, [allowance.data, currentRoundQuery.data]);

  const [currentPrice, setCurrentPrice] = useState<number>(0);

  const { isSuccess: approveIsSuccess } = useWaitForTransactionReceipt({
    hash: allowanceHash,
  });

  const { isSuccess: mintIsSuccess } = useWaitForTransactionReceipt({
    hash: hash,
  });

  const [flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    if (approveIsSuccess && !flag) {
      handleBuy();
      setFlag(true);
    }
  }, [approveIsSuccess, handleBuy, flag]);

  useEffect(() => {
    if (mintIsSuccess) {
      setPassesMintedNow((prev) => prev + 1);
    }
  }, [mintIsSuccess]);

  useEffect(() => {
    if (currentRoundQuery.data == 1) {
      setCurrentPrice(5000);
    } else if (currentRoundQuery.data == 2) {
      setCurrentPrice(200000);
    } else if (currentRoundQuery.data == 3) {
      setCurrentPrice(10000);
    }
  }, [currentRoundQuery.data]);

  const [leftThisRound, setLeftThisRound] = useState<number>(0);

  useEffect(() => {
    if (leftThisRound - passesMintedNow <= 0) {
      setMintingEnded(true);
    } else {
      setMintingEnded(false);
    }
  }, [passesMintedNow, leftThisRound]);

  useEffect(() => {
    let round = Number(currentRoundQuery.data);
    let boughtThisRound = Number(boughtThisRoundQuery.data);

    if (round == 1) {
      setLeftThisRound(FIRST_ROUND_MAX - boughtThisRound);
    } else if (round == 2) {
      setLeftThisRound(SECOND_ROUND_MAX - boughtThisRound);
    } else {
      setLeftThisRound(MAX_NFT - boughtThisRound);
    }
  }, [boughtThisRoundQuery.data, currentRoundQuery.data]);

  useEffect(() => {
    if (currentRoundQuery.data == 1) {
      setIsElgible(isWhitelisted.data);
    } else {
      setIsElgible(true);
    }
  }, [currentRoundQuery.data, isWhitelisted.data]);

  const [reachedRoundLimit, setReachedRoundLimit] = useState<boolean>(false);

  useEffect(() => {
    let round = Number(currentRoundQuery.data);
    let bought = Number(userBoughtThisRoundQuery.data) + passesMintedNow;
    if (round == 1 && bought >= 1) setReachedRoundLimit(true);
    else if (round == 2 && bought >= 1) setReachedRoundLimit(true);
    else if (round == 3 && bought >= 5) setReachedRoundLimit(true);
    else setReachedRoundLimit(false);
  }, [currentRoundQuery.data, userBoughtThisRoundQuery.data, passesMintedNow]);

  useEffect(() => {
    if (currentRoundQuery.data == 1) {
      setIsElgible(isWhitelisted.data);
    } else {
      setIsElgible(true);
    }
  }, [currentRoundQuery.data, isWhitelisted.data]);

  return (
    <Flex flexDir={"column"} my="200px" mb={{ base: "0px", md: "200px" }}>
      <Flex flexDir="column" align="flex-start" gap="24px">
        <Flex
          fontSize={{ base: "44px", md: "54px" }}
          maxW="650px"
          flexDir="column"
          gap="24px"
        >
          <Box lineHeight="100%">Focus Pass Mint</Box>
          <Box fontSize="24px" maxW="640px" color="text.500">
            <>
              Minting consists of three rounds. Connect your wallet and mint one
              of
              <Box display="inline" color="white">
                &nbsp;600 Focus Passes of random rarity!
              </Box>{" "}
            </>
          </Box>
        </Flex>

        {!isConnected ? (
          <Button variant="flare" onClick={() => open()}>
            Connect Wallet
          </Button>
        ) : (
          <Flex flexDir="column" gap="32px" mt="40px">
            {started.data === true ? (
              <>
                <Flex fontSize="24px">
                  Left this round:&nbsp;
                  <Box color="brand.500">{leftThisRound - passesMintedNow}</Box>
                </Flex>
                <Flex gap="40px">
                  <Flex flexDir="column">
                    <Box fontSize="16px" color="text.500">
                      Ongoing:
                    </Box>
                    <Box fontSize="20px">
                      Round {Number(currentRoundQuery.data)}
                    </Box>
                  </Flex>
                  {Number(currentRoundQuery.data) != 3 && (
                    <Flex flexDir="column">
                      <Box fontSize="16px" color="text.500">
                        Next round at:
                      </Box>
                      <Box fontSize="20px">
                        {Number(currentRoundQuery.data) == 1 ? "16" : "18"}:00
                        UTC
                      </Box>
                    </Flex>
                  )}
                  <Flex flexDir="column">
                    <Box fontSize="16px" color="text.500">
                      Current price:
                    </Box>
                    <Flex align="center" gap="4px" fontSize="20px">
                      {currentPrice}{" "}
                      {currentRoundQuery.data == 2 ? (
                        <Image w="20px" src="/FOCUS.png" />
                      ) : (
                        <Image w="20px" src="/flr.webp" />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                {isElgible ? (
                  mintingEnded ? (
                    <Box fontSize="24px">Round ended.</Box>
                  ) : (
                    <Button
                      maxW="300px"
                      opacity={
                        isPending || isAllowancePending || reachedRoundLimit
                          ? "0.5"
                          : "1"
                      }
                      cursor={
                        isPending || isAllowancePending || reachedRoundLimit
                          ? "default"
                          : "pointer"
                      }
                      variant="flare"
                      onClick={isAllowanceNeeded ? handleAllowance : handleBuy}
                    >
                      {isPending || isAllowancePending ? (
                        <Spinner />
                      ) : reachedRoundLimit ? (
                        "Exceeded round limit."
                      ) : isAllowanceNeeded ? (
                        "Approve FOCUS"
                      ) : (
                        "Mint"
                      )}
                    </Button>
                  )
                ) : (
                  <Button opacity="0.5">Wait for 2nd round.</Button>
                )}
                {isSuccess && hash && (
                  <Flex align="center">
                    <Box mr="10px">
                      <FaCheckCircle />{" "}
                    </Box>
                    Thank you.&nbsp;
                    <Link color="brand.500" href={`https://flare-explorer.flare.network/tx/${hash ?? ""}`}>
                      View TX on explorer -&gt;
                    </Link>
                  </Flex>
                )}
                <Box h="40px">
                  {error && !isSuccess && (
                    <Box color="red" opacity={"0.9"}>
                      Error: {(error as BaseError).shortMessage.split(":")[1]}
                    </Box>
                  )}
                </Box>
                {isConnected && (
                  <Flex
                    align="center"
                    cursor="pointer"
                    _hover={{ textDecor: "underline" }}
                    gap="8px"
                    color="brand.500"
                    fontSize={"16px"}
                    onClick={() => disconnect()}
                  >
                    Disconnect. <LockIcon />
                  </Flex>
                )}
              </>
            ) : (
              <Box>Minting starting soon...</Box>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

import { ChakraProvider, Flex, Grid, Image } from "@chakra-ui/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/global/Footer/footer";
import { Menu } from "./components/global/Menu/menu";
import { Topbar } from "./components/global/Topbar/topbar";
import { AirdropWrapper } from "./components/pages/airdrop/AirdropWrapper/airdropWrapper";
import { focusTheme } from "./theme";

import "@fontsource/inter";
import { FocusPass } from "./components/pages/home/FocusPass/focusPass";
import { FocusToken } from "./components/pages/home/FocusToken/focusToken";
import { Header } from "./components/pages/home/Header/header";
import { Tokenomics } from "./components/pages/home/Tokenomics/tokenomics";

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { MintWrapper } from "./components/pages/mint/MintWrapper/mintWrapper";
import { StakeHeader } from "./components/pages/staking/StakeHeader/stakeHeader";
import { StakeList } from "./components/pages/staking/StakeList/stakeList";
import { Timeline } from "./components/pages/staking/TImeline/timeline";
import { CenterContainer } from "./components/shared/CenterContainer/centerContainer";
import { Web3ModalProvider } from "./providers/web3ModalProvider";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Topbar />
        <Header />
        <FocusPass />
        <FocusToken />
        <Tokenomics />
        <Footer />
      </>
    ),
  },
  {
    path: "/airdrop",
    element: (
      <>
        <Flex
          flexDir="column"
          bgImage={{
            base: "/header-bg-mobile.png",
            md: "/header-background.png",
          }}
          w="100vw"
        >
          <Topbar />
          <Menu />
          <AirdropWrapper />
        </Flex>
        <Footer hideLine />
      </>
    ),
  },
  {
    path: "/staking",
    element: (
      <>
        <Topbar />
        <Menu />
        <StakeHeader/>
        <Timeline/>
        <StakeList/>
        <Footer />
      </>
    ),
  },
  {
    path: "/focus-mint",
    element: (
      <>
        <Flex
          flexDir="column"
          bgImage={{
            base: "/header-bg-mobile.png",
            md: "/header-background.png",
          }}
          w="100vw"
        >
          <Topbar />
          <Menu />
          <CenterContainer>
            <Grid alignItems="center" templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap="80px">
              
              <MintWrapper />
              <Image  my={{base:"40px", md: "auto"}} mt={{base: "0px", md : "160px"}}  src="/assets/passes/passes.png" />

            </Grid>
          </CenterContainer>
        </Flex>
        <Footer hideLine />
      </>
    ),
  },
]);

export const App = () => (
  <ChakraProvider theme={focusTheme}>
    <Web3ModalProvider>
      <RouterProvider router={router} />
    </Web3ModalProvider>
  </ChakraProvider>
);

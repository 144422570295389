import { Box, Flex } from "@chakra-ui/react";
import { CenterContainer } from "../../../shared/CenterContainer/centerContainer";

export const StakeHeader = () => {
  return (
    <CenterContainer>
      <Flex flexDir="column"  align="center" textAlign="center"  mt="200px" gap="8px">
        <Box fontSize="48px"><Box display={"inline"} color="brand.500">Focus</Box> Pass Staking</Box>
        <Box fontSize='20px' maxW='500px' color='text.500'>Stake your Focus Passes with us & <Box display="inline" color="text.900">claim monthly rewards</Box> based on their rarities! Remember to collect your rewards every month!</Box>
      </Flex>
    </CenterContainer>
  );
};

import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button : ComponentStyleConfig = {
    baseStyle: {
        borderRadius: "8px",
       
       
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {
        md: {
            h: "40px",
            px:"32px",
        },
        lg: {
            px:"40px",
            h: "58px"
        }
    },
    // styles for different visual variants ("outline", "solid")
    variants: {
        "outlined" : {
            border: "1px solid",
            borderColor: "white"
        },
        "flare" : {
            borderRadius: "32px 0px 32px 0px",
            bg: "brand.500"

        }
    },
    // default values for 'size', 'variant' and 'colorScheme'
    defaultProps: {
      size: 'lg',
      variant: 'flare',
      colorScheme: "red"
    },
  }
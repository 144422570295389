import { useEffect, useState } from 'react';


export const useCountdown = (targetYear:number, targetMonth: number, targetDay : number, targetHour = 0, targetMinute = 0, targetSecond = 0)  => {
 
  const countDownDate = Date.UTC(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute, targetSecond);

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [passed, setPassed] = useState<boolean>(false);


  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;


      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setPassed(true);
      } else {
        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetYear, targetMonth, targetDay, targetHour, targetMinute, targetSecond, countDownDate]);

  return {...countdown, passed};
}
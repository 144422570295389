import { Box, Flex, Grid, Heading, Image, Text } from "@chakra-ui/react";
import { Anchor } from "../../../shared/Anchor/anchor";
import { CenterContainer } from "../../../shared/CenterContainer/centerContainer";
import { Stages } from "./Stages/stages";

const BENEFITS: { label: string; grayText: string }[] = [
  {
    label: "Rarity Shares",
    grayText:
      "Pass ownership provides prize pool shares with rewards including $FLR and $FOCUS tokens.",
  },
  {
    label: "Royalties Contribution",
    grayText:
      "50% of monthly #NFT sales royalties fund the pool, benefiting 600 NFT holders.",
  },
  {
    label: "$FOCUS Rewards",
    grayText:
      " Stake your $FLR with FocusTSO1 and FocusTSO2 VALIDATORS for $FOCUS rewards. Pass holders enjoy a higher multiplier.",
  },
  {
    label: "Exclusive Club Benefits",
    grayText: "Join the Focus Club to access special events.",
  },
  {
    label: "Community Contributions",
    grayText: "Holding the Focus Pass unlocks bonuses in future products",
  },
];

const BenefitItem = ({
  data,
}: {
  data: { label: string; grayText: string };
}) => {
  return (
    <Flex align="flex-start" gap="16px">
      <Image w={{ base: "24px", md: "30px" }} src="/bullet.svg" />
      <Flex flexDir="column">
        <Box fontSize={{ base: "18px", md: "18px" }}>{data.label}</Box>

        <Box
          color="text.500"
          fontSize={{ base: "14px", md: "14px" }}
          maxW="400px"
        >
          {data.grayText}
        </Box>
      </Flex>
    </Flex>
  );
};

export const FocusPass = () => {

  return (
    <CenterContainer>
      <Anchor id="pass" />
      <Grid
        mt={{ base: "260px", md: "120px" }}
        gap={{ base: "80px", md: "140px" }}
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      >
        <Flex flexDir="column" gap="48px">
          <Heading
            fontWeight="normal"
            fontSize={{ base: "48px", md: "54px" }}
            as="h3"
          >
            <Box color="brand.500" display="inline">
              Focus
            </Box>{" "}
            Pass
          </Heading>
          <Text color="text.500" fontSize={{ base: "20px", md: "18px" }}>
            NFT Passes allow users to earn additional rewards. The total supply
            is{" "}
            <Box color="white" display="inline">
              600 NFT Passes, divided into 4 rarities
            </Box>{" "}
            with a different number of passes in each category. Each rarity will
            have a different pool ratio for the rewards.
          </Text>

          <Flex flexDir="column" gap="16px">
            {BENEFITS.map((data) => {
              return <BenefitItem key={data.label} data={data} />;
            })}
          </Flex>

         
        </Flex>
        <Flex mt={{ base: "0px", md: "140px" }} align="center" justify="center">
          <Image zIndex={"2"} src="/assets/passes/passes.png" />
          <Box pos="absolute" bg="brand.500" ml="100px" zIndex={"1"} boxSize="200px" filter={"blur(250px)"}/>

        </Flex>
      </Grid>
      <Flex mt="160px" mb="100px" flexDir="column" align="center" gap="100px">
       
        <Stages />
      </Flex>
    </CenterContainer>
  );
};

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { flare } from 'wagmi/chains'

// 0. Setup queryClient
const queryClient = new QueryClient()

const projectId = '80c38ef7ed524612846e25eda0e04e43'

const metadata = {
  name: 'Focus',
  description: 'Focus',
  url: 'https://focuslfr.app', // origin must match your domain & subdomain
  icons: [""]
}


const chains = [flare] as const
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
})

export function Web3ModalProvider({ children } : {children : React.ReactNode}) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
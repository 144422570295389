import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { Button } from "./components/button";

export const focusTheme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Inter",
        color: "white",
        bg:"1d1d1d"
      },
    },
  },
  colors: colors,
  components: {
    Button,
  },
});

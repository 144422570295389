import {
  Button,
  Flex,
  Image,
  Link,
  useDisclosure
} from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";
import { useCountdown } from "../../../hooks/useCountdown";
import { CenterContainer } from "../../shared/CenterContainer/centerContainer";

const MENU_ITEMS: { text: string; href: string }[] = [
  {
    text: "Focus Pass",
    href: "/#pass",
  },
  {
    text: "Token",
    href: "/#token",
  },
  
  {
    text: "Tokenomics",
    href: "/#tokenomics",
  },
];

const MenuItem = ({ text, href }: { text: string; href: string }) => {
  return (
    <Flex
      fontSize={{ base: "24px", md: "16px" }}
      fontWeight="medium"
      letterSpacing="4%"
    >
      <HashLink to={href}>{text}</HashLink>
    </Flex>
  );
};

export const Menu = () => {
  const mobileMenu = useDisclosure();
  const { days, minutes, hours, seconds, passed } = useCountdown(
    2024,
    5,
    23,
    17
  );

  return (
    <Flex
      bg={{ base: "rgba(0,0,0,0.95)", md: "none" }}
      zIndex={"999"}
      flexDir="column"
      pos="absolute"
      top={{ base: "40px", md: "0" }}
      left="0"
      right="0"
      margin="auto"
    >
      {mobileMenu.isOpen && (
        <Flex
          zIndex={"987"}
          pos="fixed"
          px="32px"
          w="100vw"
          flexDir="column"
          h="100vh"
          bg="#0d0d0d"
          top="0"
          left="0"
          display={{ base: "flex", md: "none" }}
        >
          <Flex align="center" justify="space-between" pt="60px">
            <Link href="/">
              <Image
                zIndex={"999"}
                ml="-4px"
                src="/logo.svg"
                alt="focus logo"
                w={{ base: "120px", md: "auto" }}
              />
            </Link>

            <Flex
              onClick={mobileMenu.onClose}
              display={{ base: "flex", md: "none" }}
              flexDir="column"
              align="flex-end"
              pos="relative"
              gap="8px"
              boxSize="40px"
            >
              <Flex
                pos="absolute"
                left="0"
                right="0"
                top="0"
                bottom="0"
                margin="auto"
                transform="rotate(135deg)"
                w="40px"
                h="4px"
                borderRadius="4px"
                bg="white"
              ></Flex>
              <Flex
                pos="absolute"
                left="0"
                right="0"
                top="0"
                bottom="0"
                margin="auto"
                transform="rotate(45deg)"
                w="40px"
                h="4px"
                borderRadius="4px"
                bg="brand.500"
              ></Flex>
            </Flex>
          </Flex>

          <Flex flexDir="column" mt="160px" onClick={mobileMenu.onClose}>
            <Flex align="center" gap="40px" flexDir="column">
              {MENU_ITEMS.map((item) => {
                return (
                  <MenuItem href={item.href} text={item.text} key={item.href} />
                );
              })}
              {passed ? (
                <Link href={"/staking"}>
                  <Button role="group" minW="200px" cursor={"pointer"}>
                    Stake
                  </Button>
                </Link>
              ) : (
                <Link href={"/#reveal"}>
                  <Button role="group" minW="200px" cursor={"pointer"}>
                    Reveal Rarity
                  </Button>
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
      <CenterContainer>
        <Flex
          justify="space-between"
          my={{ base: "20px", md: "50px" }}
          align="center"
        >
          <Link href="/">
            <Image
              zIndex={"988"}
              ml="-4px"
              src="/logo.svg"
              alt="focus logo"
              w={{ base: "120px", md: "auto" }}
            />
          </Link>
          <Flex
            align="center"
            gap="40px"
            display={{ base: "none", md: "flex" }}
          >
            {MENU_ITEMS.map((item) => {
              return (
                <MenuItem href={item.href} text={item.text} key={item.href} />
              );
            })}
             {passed ? (
                <Link href={"/staking"}>
                  <Button role="group" minW="200px" cursor={"pointer"}>
                    Stake
                  </Button>
                </Link>
              ) : (
                <Link href={"/#reveal"}>
                  <Button role="group" minW="200px" cursor={"pointer"}>
                    Reveal Rarity
                  </Button>
                </Link>
              )}
          </Flex>
          <Flex
            onClick={mobileMenu.onOpen}
            display={{ base: "flex", md: "none" }}
            flexDir="column"
            align="flex-end"
            gap="8px"
          >
            <Flex w="40px" h="4px" borderRadius="4px" bg="white"></Flex>
            <Flex w="30px" h="4px" borderRadius="4px" bg="white"></Flex>
            <Flex w="20px" h="4px" borderRadius="4px" bg="white"></Flex>
          </Flex>
        </Flex>
      </CenterContainer>
    </Flex>
  );
};

import { CheckCircleIcon, LinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Grid, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { FOCUS_ADDRESS } from "../../../../config/blockchain";
import { Anchor } from "../../../shared/Anchor/anchor";
import { CenterContainer } from "../../../shared/CenterContainer/centerContainer";

const TOKENOMIC_ITEMS: {
  content: string;
  percentage: number;
  color: string;
  grayText: string;
}[] = [
  {
    content: "Incentives for Focus Pass Staking",
    percentage: 19,
    color: "#E6225A",
    grayText: "103,550,000 FOCUS",
  },
  {
    content: "Project development",
    percentage: 15,
    color: "#C23381",
    grayText: "81,750,000 FOCUS",
  },
  {
    content: "Promotion and marketing",
    percentage: 13,
    color: "#8E4893",
    grayText: "70,850,000 FOCUS",
  },
  {
    content: "Airdrops / Transferred from XRPL",
    percentage: 19,
    color: "#59518E",
    grayText: "103,550,000 FOCUS",
  },
  {
    content: "Liquidity for DEX",
    percentage: 22,
    color: "#345077",
    grayText: "119,900,400 FOCUS",
  },
  {
    content: "Incentive pool for delegators",
    percentage: 12,
    color: "#2F4858",
    grayText: "65,400,000 FOCUS",
  },
];

export const Tokenomics = () => {
  const [animate, setAnimate] = useState<boolean>(false);

  const handleCopyAddress = () => {
    if (animate == false) {
      navigator.clipboard.writeText(FOCUS_ADDRESS);
      setAnimate(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 500);
  }, [animate]);

  return (
    <CenterContainer>
      <Anchor id="tokenomics" />
      <Grid
        my="40px"
        mb={{ base: "80px", md: "160px" }}
        gap={{ base: "40px", md: "100px" }}
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      >
        <Flex flexDir="column" gap={{base: "32px", md: "48px"}}>
          <Heading
            mb={{base:"16px", md: "0px"}}
            fontWeight="normal"
            fontSize={{ base: "48px", md: "54px" }}
            as="h3"
          >
            Tokenomics
          </Heading>
          <Flex flexDir="column" gap="32px">
            <Flex gap="40px">
              <Flex flexDir="column">
                <Box color="text.500" fontSize="16px">
                  Total Supply
                </Box>
                <Box fontSize="20px">545,000,000</Box>
              </Flex>

              <Flex flexDir="column">
                <Box color="text.500" fontSize="16px">
                  Contract
                </Box>
                <Flex
                  onClick={handleCopyAddress}
                  cursor="pointer"
                  align="center"
                  fontSize="20px"
                  gap="4px"
                >
                  <Flex>
                    {FOCUS_ADDRESS.slice(0, 6)}...{FOCUS_ADDRESS.slice(-4)}
                  </Flex>
                  {animate ? <CheckCircleIcon /> : <LinkIcon />}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDir="column">
            {TOKENOMIC_ITEMS.map((item) => {
              return (
                <Flex
                  gap={{ base: "20px", md: "32px" }}
                  borderRadius="8px"
                  h="60px"
                  key={item.content}
                  align="center"
                >
                  <Flex w="70px" align="center" gap="8px">
                    <Box boxSize="22px" borderRadius="6px" bg={item.color} />
                    <Box fontSize="20px">{item.percentage}%</Box>
                  </Flex>
                  <Flex flexDir="column">
                    <Box
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight="120%"
                    >
                      {item.content}
                    </Box>
                    <Box
                      fontSize={{ base: "12px", md: "14px" }}
                      color="text.500"
                    >
                      {item.grayText}
                    </Box>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="16px">
          <Flex
            flexDir="column"
            pos="relative"
            maxW={{ base: "100vw", md: "800px" }}
          >
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              textAlign="center"
              w="240px"
              h="40px"
              pos="absolute"
              left="0"
              top="0"
              bottom="0"
              right="0"
              margin={"auto"}
            >
              <Box fontSize={{ base: "14px", md: "20px" }} color="text.500">
                Total Supply
              </Box>
              <Box display={{ base: "none", md: "flex" }} fontSize="24px">
                545,000,000 FOCUS
              </Box>
              <Box display={{ base: "flex", md: "none" }} fontSize="20px">
                545M FOCUS
              </Box>
            </Flex>
            <Doughnut
              datasetIdKey="id"
              options={{
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                },
                cutout: "60%",
                elements: {
                  arc: {
                    borderWidth: 8,
                    borderRadius: 8,
                    borderColor: "#111",
                  },
                },
              }}
              data={{
                datasets: [
                  {
                    label: "",
                    data: TOKENOMIC_ITEMS.map((item) => item.percentage),
                    backgroundColor: TOKENOMIC_ITEMS.map((item) => item.color),
                  },
                ],
              }}
            />
          </Flex>
        </Flex>
      </Grid>
    </CenterContainer>
  );
};

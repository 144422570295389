import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { FaCoins, FaLock, FaUndoAlt, FaUser } from "react-icons/fa";
import {
  useAccount,
  useDisconnect,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { useEthersProvider } from "../../../../providers/ethersProvider";
import { CenterContainer } from "../../../shared/CenterContainer/centerContainer";
import { rarities } from "../../home/FocusPass/Reveal/rarity";
import { NFTABI } from "./NFTABI";
import { stakingABI } from "./stakingABI";

const STAKING_ADDRESS = "0x7D986Ed580f5E818d2c303BecD291c6dBeed4eF1"; // Mainnet: 0x70F869639378466DB37b6438783D61A1a6800cc2
const PASS_ADDRESS = "0x4FD29d6c713a390Aa38b650254D0cD38a4982dBD"; // Mainnet: 0x4FD29d6c713a390Aa38b650254D0cD38a4982dBD

console.log(STAKING_ADDRESS)

const StakeItem = ({
  index,
  i,
  rarity,
  deleteItem,
}: {
  index: number;
  i: number;
  rarity: number;
  deleteItem: (id: number) => void;
}) => {
  const {
    data: hash,
    isPending,
    isSuccess,
    writeContractAsync,
  } = useWriteContract();

  const handleUnstake = (token: number, index: number) => {
    writeContractAsync({
      abi: stakingABI,
      address: STAKING_ADDRESS,
      functionName: "unstake",
      args: [token, index],
    })
      .then(() => {
        deleteItem(token);
      })
      .catch((e) => {
        console.log(index);
      });
  };
  

  return (
    <Flex
      align="center"
      justify="space-between"
      borderRadius="12px"
      border="1px solid rgba(255,255,255,0.1)"
      w={{ base: "auto", md: "600px" }}
    >
      <Flex align="center" gap="24px">
        <Flex h="60px">
          {rarity == 1 && <Image src="/assets/passes/red-square.png" />}
          {rarity == 2 && <Image src="/assets/passes/blue-square.png" />}
          {rarity == 3 && <Image src="/assets/passes/green-square.png" />}
          {rarity == 4 && <Image src="/assets/passes/gold-square.png" />}
        </Flex>
        <Flex>Focus Pass #{index + 1}</Flex>
      </Flex>

      <Button
        onClick={() => handleUnstake(index, i)}
        _hover={{ color: "black", bg: "white" }}
        variant="outline"
        px="12px"
        fontSize="14px"
        fontWeight="normal"
        color="white"
        borderColor="white"
        maxH="40px"
        mr="10px"
        gap="12px"
        justifySelf="flex-end"
      >
        {isPending ? (
          <Spinner />
        ) : (
          <>
            <Box display={{ base: "none", md: "flex" }}>Unstake</Box>{" "}
            <FaUndoAlt />{" "}
          </>
        )}
      </Button>
    </Flex>
  );
};

const StakeDashboardItem = ({
  children,
  label,
  icon,
}: {
  children: React.ReactNode;
  label: string;
  icon: React.ReactNode;
}) => {
  return (
    <Flex
      _hover={{ bg: "rgba(255,255,255,0.05)" }}
      align="center"
      gap="24px"
      border="1px solid rgba(255,255,255,0.1)"
      p="12px"
      px="24px"
      borderRadius="12px"
    >
      {icon}
      <Flex flexDir="column">
        <Box fontSize="14px" color="text.500">
          {" "}
          {label}
        </Box>
        <Box fontSize="18px">{children}</Box>
      </Flex>
    </Flex>
  );
};

export const StakeList = () => {
  const { open } = useWeb3Modal();

  const { isConnected, address } = useAccount();

  const [flag, setFlag] = useState<boolean>(false);

  const [totalTokenClaimed, setTotalTokenClaimed] = useState<number>(0);
  const [totalFlrClaimed, setTotalFlrClaimed] = useState<number>(0);

  const [passesStaked, setPassesStaked] = useState<number[]>([]);

  const queryAmount = useReadContract({
    abi: stakingABI,
    address: STAKING_ADDRESS,
    functionName: "getStaked",
    args: [address],
  }) as any;

  const queryRewadsEthers = useReadContract({
    abi: stakingABI,
    address: STAKING_ADDRESS,
    functionName: "claimedRewardsEther",
    args: [address],
  }) as any;

  const queryRewadsToken = useReadContract({
    abi: stakingABI,
    address: STAKING_ADDRESS,
    functionName: "claimedRewardsToken",
    args: [address],
  }) as any;

  const queryUserRewadsEthers = useReadContract({
    abi: stakingABI,
    address: STAKING_ADDRESS,
    functionName: "userRewardsEther",
    args: [address],
  }) as any;

  const queryUserRewadsToken = useReadContract({
    abi: stakingABI,
    address: STAKING_ADDRESS,
    functionName: "userRewardsToken",
    args: [address],
  }) as any;



  useEffect(() => {
    if (queryAmount.data) {
      if (queryAmount.data.length > 0) {
        console.log(queryAmount.data);
        let numbers = queryAmount.data.map((n: any) => Number(n));
        setPassesStaked(numbers);
      }
    }
  }, [queryAmount.data]);

  const {
    data: hash,
    isPending,
    isSuccess,
    writeContractAsync,
  } = useWriteContract();

  const {
    data: allowanceHash,
    isPending: isAllowancePending,
    isSuccess: isAllowanceSuccess,
    isError: isAllowanceError,
    writeContract: addAllowance,
  } = useWriteContract();
  const {
    data: claimHash,
    isPending: claimPending,
    isSuccess: claimSuccess,
    writeContractAsync: claimWrite,
  } = useWriteContract();

  const handleAllowance = async () => {
    let owns = await fetchContractValue(Number(id) - 1);

    if (owns) {
      setNotYourToken("");

      addAllowance({
        address: PASS_ADDRESS,
        abi: NFTABI,
        functionName: "approve",
        args: [STAKING_ADDRESS, Number(id) - 1],
      });

      setFlag(true);
    } else {
      setNotYourToken(`You are not owner of token #${id} or already staking.`);
    }
  };

  const { isSuccess: approveIsSuccess, isLoading: allowancePending } =
    useWaitForTransactionReceipt({
      hash: allowanceHash,
    });

  useEffect(() => {
    if (queryRewadsEthers.data) {
      setTotalFlrClaimed(Number(queryRewadsEthers.data));
    }
  }, [queryRewadsEthers.data]);

  useEffect(() => {
    if (queryRewadsToken.data) {
      setTotalTokenClaimed(Number(queryRewadsToken.data));
    }
  }, [queryRewadsToken.data]);

  const [claimableTokenReward, setClaimableTokenReward] = useState<number>(0);
  const [claimableFlrReward, setClaimableFlrReward] = useState<number>(0);

  const [notYourToken, setNotYourToken] = useState<string>("");

  useEffect(() => {
    if (queryUserRewadsToken.data) {
      setClaimableTokenReward(Number(queryUserRewadsToken.data));
    }
  }, [queryUserRewadsToken.data]);

  useEffect(() => {
    if (queryUserRewadsEthers.data) {
      setClaimableFlrReward(Number(queryUserRewadsEthers.data));
    }
  }, [queryUserRewadsEthers.data]);

  const handleDeleteItem = (id: number) => {
    setPassesStaked((prevNumbers) => {
      if (prevNumbers.length <= 1) return []; 

      let index = prevNumbers.indexOf(id);

      const newNumbers = [...prevNumbers];
      newNumbers[index] = newNumbers[newNumbers.length - 1];
      newNumbers.pop();
      return newNumbers;
  });
  };

  const provider = useEthersProvider();

  const fetchContractValue = async (tokenId: number) => {
    try {
      const contract = new ethers.Contract(PASS_ADDRESS, NFTABI, provider);

      const value = await contract.ownerOf(tokenId);

      console.log(value);
      console.log(address);

      let result = value.toLocaleLowerCase() == address?.toLocaleLowerCase();

      console.log(result);

      return result;
    } catch (error) {
      console.error("Error reading contract value:", error);
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleStake = useCallback(
    async (tokenId: number) => {
      writeContractAsync({
        abi: stakingABI,
        address: STAKING_ADDRESS,
        functionName: "stake",
        args: [tokenId - 1],
      })
        .then(() => {
          setPassesStaked((prev) => prev.concat([tokenId - 1]));
          onClose();
        })
        .catch((e) => {});
    },
    [onClose, writeContractAsync]
  );

  const [id, setId] = useState<string>("");

  const handleClaim = () => {
    claimWrite({
      abi: stakingABI,
      address: STAKING_ADDRESS,
      functionName: "claimReward",
      args: [],
    })
      .then(() => {
        let claimableFlr = claimableFlrReward;
        let claimableToken = claimableTokenReward;

        setTotalTokenClaimed((prev) => prev + claimableToken);
        setTotalFlrClaimed((prev) => prev + claimableFlr);

        setClaimableTokenReward(0);
        setClaimableFlrReward(0);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (approveIsSuccess && flag) {
      setFlag(false);
      handleStake(Number(id));
    }
  }, [approveIsSuccess, handleStake, id, flag]);

  useEffect(() => {
    if (Number(id) > 600) {
      setId("600");
    } else if (Number(id) < 0) {
      setId("1");
    } else if (id.length > 3) {
      setId((prev) => prev.slice(0, 3));
    }
  }, [id]);

  const { disconnect } = useDisconnect()

  return (
    <CenterContainer>
      {isConnected && <Flex justify="center" mt='10px' cursor='pointer' onClick={() => disconnect()} color="brand.500">Disconnect.</Flex>}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="24px" p="24px" bg="#121212">
          <ModalHeader>Enter Your Focus Pass #</ModalHeader>
          <ModalCloseButton top="38px" right="38px" />
          <ModalBody mb="24px" mt="12px">
            {isAllowancePending || isPending ? (
              <Flex align="center" gap="24px">
                <Spinner /> <Box>Accept TX in wallet...</Box>{" "}
              </Flex>
            ) : allowancePending ? (
              <Flex align="center" gap="12px">
                {" "}
                <Spinner /> Processing approval...
              </Flex>
            ) : (
              <Grid templateColumns="1fr auto" gap="8px">
                <Grid pos="relative">
                  <Box
                    color="brand.500"
                    position={"absolute"}
                    fontSize="32px"
                    left="15px"
                    top="0px"
                    bottom={"0"}
                    lineHeight="150%"
                  >
                    #
                  </Box>
                  <Input
                    h="50px"
                    borderColor="rgba(255,255,255,0.3)"
                    value={id.toString()}
                    placeholder="0"
                    pl="45px"
                    maxLength={3}
                    fontSize="24px"
                    type="number"
                    onChange={(e) =>
                      e.target.value.length < 4
                        ? setId(e.target.value)
                        : () => {}
                    }
                  />
                </Grid>
                <Button
                  opacity={id != "0" && id != "" ? "1" : "0.7"}
                  cursor={id != "0" && id != "" ? "pointer" : "defeault"}
                  h="50px"
                  borderRadius="8px"
                  onClick={
                    id != "0" && id != "" ? () => handleAllowance() : () => {}
                  }
                >
                  Stake
                </Button>
              </Grid>
            )}
            {notYourToken && (
              <Box color="red" mt="16px">
                {notYourToken}
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {isConnected && address ? (
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr auto" }}
          margin="0 auto"
          my="40px"
          gap="16px"
        >
          <StakeDashboardItem
            icon={<FaUser size={32} color="#E6245A" />}
            label="Account"
          >
            {address.slice(0, 5)}...{address.slice(-5)}
          </StakeDashboardItem>
          <StakeDashboardItem
            icon={<FaLock rotate="1" size={32} color="#E6245A" />}
            label="Passes Staked"
          >
            <Box display="inline" color="text.500">
              x
            </Box>
            {passesStaked.length}
          </StakeDashboardItem>

          {claimableFlrReward > 0 || claimableTokenReward > 0 ? (
            <Button
              w={{ base: "auto", md: "200px" }}
              onClick={handleClaim}
              borderRadius="12px"
              h="70px"
              fontWeight="normal"
              fontSize="16px"
            >
              {claimPending ? (
                <Spinner />
              ) : (
                <Box fontSize="18px">
                  Claim Rewards!{" "}
                  <Box mt="4px" fontSize="14px">
                    {(claimableFlrReward.toString()).slice(0,-18)} FLR + {(claimableTokenReward.toString()).slice(0,-18)} FOCUS
                  </Box>
                </Box>
              )}
            </Button>
          ) : (
            <StakeDashboardItem
              icon={<FaCoins rotate="1" size={32} color="#E6245A" />}
              label="Claimed rewards"
            >
              <Flex w="150px" align="center" gap="24px">
                <Flex gap="6px" align="center">
                {(totalTokenClaimed.toString()).slice(0,-18)} <Image boxSize="18px" src="/FOCUS.png" />
                </Flex>
                <Flex gap="6px" align="center">
                {(totalFlrClaimed.toString()).slice(0,-18)}  <Image boxSize="18px" src="/flr.webp" />{" "}
                </Flex>
              </Flex>
            </StakeDashboardItem>
          )}
        </Grid>
      ) : (
        <Flex flexDir="column" mt="16px" align="center">
          <Button mt="24px" onClick={() => open()}>
            Connect Wallet
          </Button>
        </Flex>
      )}
      {isConnected && (
        <Flex flexDir="column" align="center" gap="24px" mt="40px">
          <Box fontSize="24px">Your staked passes:</Box>
          <Flex
            flexDir="column"
            align={{ base: "auto", md: "center" }}
            w={{ base: "100%", md: "auto" }}
            gap="8px"
          >
            <Flex
              align="center"
              gap="12px"
              onClick={onOpen}
              cursor="pointer"
              lineHeight="100%"
              _hover={{ bg: "#121212" }}
              borderRadius="12px"
              border="1px solid rgba(255,255,255,0.1)"
              w={{ base: "auto", md: "600px" }}
            >
              <Flex
                height="60px"
                ml="30px"
                fontSize="24px"
                mb="3px"
                align="center"
                justify="center"
                color="gray"
                borderRadius="4px"
              >
                +
              </Flex>
              <Flex align="center">Stake new pass</Flex>
            </Flex>
            {passesStaked.map((index, i) => {
              let r = rarities[index].rarity;
              let rarity = 0;

              if (r == "Rare") {
                rarity = 1;
              } else if (r == "Epic") {
                rarity = 2;
              } else if (r == "Legendary") {
                rarity = 3;
              } else if (r == "Mythical") {
                rarity = 4;
              }

              return (
                <StakeItem
                  index={index}
                  i={i}
                  rarity={rarity}
                  deleteItem={handleDeleteItem}
                />
              );
            })}
          </Flex>
        </Flex>
      )}
      <Box mb="100px" />
    </CenterContainer>
  );
};

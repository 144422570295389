import {
    Box,
    Button,
    Flex,
    Grid,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Fireworks from "react-canvas-confetti/dist/presets/explosion";
import { rarities } from "./rarity";

function Example({ init }: { init: () => void }) {
  return <Fireworks onInit={init} autorun={{ speed: 0.0001 }} />;
}

export const Reveal = () => {
  const [id, setId] = useState<string>("");

  useEffect(() => {
    if (Number(id) > 600) {
      setId("600");
    } else if (Number(id) < 0) {
      setId("1");
    } else if (id.length > 3) {
      setId((prev) => prev.slice(0, 3));
    }
  }, [id]);

  const [rarity, setRarity] = useState<number>(0);

  const fetchRarity = (id : string) => {
    let fetched = rarities[Number(id ) - 1];
    if(fetched) {
        let r = fetched.rarity;
        
        if(r == "Rare") {
            setRarity(1)
        } else if(r == "Epic") {
            setRarity(2)
        } else if (r == "Legendary") {
            setRarity(3)
        } else if(r == "Mythical") {
            setRarity(4)
        }

    }
  };

  const [loading, setLoading] = useState<boolean>(false);

  const reveal = useDisclosure();

  const handleReveal = () => {
    if (!loading && id.length > 0 && id != "0" ) {
      console.log(Number(id));
      setLoading(true);

      setTimeout(() => {
        reveal.onOpen();
        fetchRarity(id);
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      {reveal.isOpen && (
        <Modal isCentered isOpen={reveal.isOpen} onClose={reveal.onClose}>
          <ModalOverlay />
          <ModalContent borderRadius="24px" bg="#121212" padding="24px">
            <ModalBody  display="flex" flexDir="column" justifyContent="center">
              <Flex
                flexDir={"column"}
                justify="center"
                align="center"
                my="48px"
              >
                <Box my="20px" fontSize="30px">Focus Pass #{id}</Box>
                {rarity == 1 && <Image src="/assets/passes/red-square.png" />}
                {rarity == 2 && <Image src="/assets/passes/blue-square.png" />}
                {rarity == 3 && <Image src="/assets/passes/green-square.png" />}
                {rarity == 4 && <Image src="/assets/passes/gold-square.png" />}
                <Flex justify="center" my="12px" fontSize="24px">
                  {rarity == 1 && <Box color="brand.500">Rare</Box>}
                  {rarity == 2 && <Box color="#0578FF">Epic</Box>}
                  {rarity == 3 && <Box color="#1FDD48">Legendary</Box>}
                  {rarity == 4 && <Box color="#C9BA20">Mythical</Box>}
                </Flex>
                <Box zIndex={"10000"}>
                  <Example init={() => {}} />
                </Box>
              </Flex>
              <Button onClick={() => reveal.onClose()}>OK!</Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Flex flexDir="column" gap="8px" align="center">
        <Box fontSize="16px" color={"text.500"}>
          Enter your Focus Pass Number {"(#)"}
        </Box>
        <Grid templateColumns={"1fr auto"} gap="8px">
          <Grid pos="relative">
            <Box
              color="brand.500"
              position={"absolute"}
              fontSize="32px"
              left="15px"
              top="4px"
              bottom={"0"}
              lineHeight="150%"
            >
              #
            </Box>
            <Input
              h="58px"
              borderColor="rgba(255,255,255,0.3)"
              value={id.toString()}
              placeholder="0"
              pl="45px"
              maxW="110px"
              maxLength={3}
              fontSize="24px"
              type="number"
              onChange={(e) =>
                e.target.value.length < 4 ? setId(e.target.value) : () => {}
              }
            />
          </Grid>
          <Button
            opacity={id.length > 0 && !loading && id != "0" ? "1" : "0.5"}
            cursor={id.length > 0 && !loading  && id != "0" ? "pointer" : "default"}
            onClick={handleReveal}
            borderRadius="8px"
          >
            {loading ? <Spinner /> : "Reveal"}
          </Button>
        </Grid>
      </Flex>
    </>
  );
};

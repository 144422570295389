import { Box, Flex } from "@chakra-ui/react";
import { useCountdown } from "../../../../../hooks/useCountdown";
import { Anchor } from "../../../../shared/Anchor/anchor";
import { Reveal } from "../Reveal/reveal";

const StageItem = ({
  name,
  duration,
  token,
  price,
  isWhitelist,
  max,
  amount,
}: {
  name: string;
  duration: string;
  token: React.ReactNode;
  price: string;
  isWhitelist: boolean;
  max: number;
  amount: number;
}) => {
  return (
    <Flex
      p="32px"
      w={{ base: "calc(100vw - 64px)", md: "300px" }}
      borderRadius="8px"
      border="1px solid"
      borderColor="rgba(255,255,255,0.1)"
      gap="24px"
      bgImage="/header-background.png"
      bgSize="cover"
    >
      <Flex flexDir="column">
        <Box color="brand.500" fontWeight="bold" fontSize="32px" mb="4px">
          Round {name}
        </Box>
        <Flex gap="8px">
          <Box color="text.500">Amount minted:</Box>
          <Box color="text.900">{amount}</Box>
        </Flex>
        <Flex gap="8px">
          <Box color="text.500">Duration:</Box>
          <Box color="brand.900">{duration}</Box>
        </Flex>
        <Flex gap="8px" align="center">
          <Box color="text.500">Price:</Box>
          <Flex color="brand.900" gap="4px" align="center">
            {price}
            {token}
          </Flex>
        </Flex>
        <Flex gap="8px">
          <Box color="text.500">Max per wallet:</Box>
          <Flex color="brand.900" gap="4px">
            {max}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const Stages = () => {
  const { days, minutes, hours, seconds, passed } = useCountdown(
    2024,
    4,
    22,
    15,
56
  );

  return (
    <Flex flexDir="column" gap="60px" align="center" pos="relative">
      <Anchor id="reveal"/>
      <Flex
        align="center"
        lineHeight="160%"
        fontSize={{ base: "24px", md: "38px" }}
        flexDir="column"
        gap="16px"
      >
        {!passed && (
          <>
            <Box onClick={() => console.log("OK")} color="brand.500">Reveal starts in:</Box>
            <Flex fontSize={{ base: "36px", md: "50px" }} mb="8px">
              {days}d {hours}h {minutes}m {seconds}s
            </Flex>
          </>
        )}

        {passed && (
          <>
            <Flex textAlign="center" color="white" display="inline">Click & reveal your<Box display={"inline"} color="brand.500">&nbsp;Focus&nbsp;Pass rarity!</Box></Flex>
           
              <Reveal/>
           
          </>
        )}
      </Flex>
      {/* <Flex
        gap={{ base: "16px", md: "40px" }}
        flexDir={{ base: "column", md: "row" }}
      >
        <StageItem
          name="1"
          duration="1h"
          token={
            <Flex align="center" gap="4px">
              FLR <Image w="20px" src="/flr.webp" />
            </Flex>
          }
          price={"5000"}
          isWhitelist={true}
          amount={30}
          max={1}
        />
        <StageItem
          name="2"
          duration="2h"
          token={
            <Flex align="center" gap="4px">
              FOCUS <Image w="20px" src="/sygnet.svg" />
            </Flex>
          }
          price={"200 000"}
          amount={60}
          isWhitelist={false}
          max={1}
        />
        <StageItem
          name="3"
          duration="Unlimited"
          token={
            <Flex align="center" gap="4px">
              FLR <Image w="20px" src="/flr.webp" />
            </Flex>
          }
          price={"10 000"}
          amount={510}
          isWhitelist={false}
          max={5}
        />
      </Flex> */}
    </Flex>
  );
};

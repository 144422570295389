import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { TELEGRAM_URL, TWITTER_URL } from "../../../config/urls";
import { CenterContainer } from "../../shared/CenterContainer/centerContainer";


const FOOTER_LINKS: {
  heading: string;
  items: { text: string; href: string }[];
}[] = [
  {
    heading: "Resources",
    items: [
      {
        text: "Flaremetrics",
        href: "https://flaremetrics.io/",
      },
      {
        text: "Flare explorer (RPC)",
        href: "https://flare-explorer.flare.network/",
      },
      {
        text: "Songbird explorer (RPC)",
        href: "https://songbird-explorer.flare.network/",
      },
    ],
  },
  {
    heading: "About",
    items: [
      {
        text: "flr.globalfcx",
        href: "https://flr.globalfcx.com/",
      },
     
     
    ],
  },
];

export const Footer = ({hideLine} : {hideLine? : boolean}) => {
  return (
    <CenterContainer>
   

      {!hideLine ? <Flex my="40px" h="1px" bg="rgba(255,255,255,0.1)" /> :  <Flex mt="80px"/>}
      <Flex gap="80px"> 
        <Flex flexDir="column" w="500px" gap="24px" align={{base: "center", md : "flex-start"}}>
          <Image ml="-4px" mt="-4px" w="150px" src="/logo.svg" />
          <Box  textAlign={{base: 'center', md : "initial"}} fontSize="20px" maxW="400px">
            We connect<br/> the Community.
          </Box>
          <Flex gap="20px" align="center">
            <Link href={TELEGRAM_URL}><Image src="/telegram-plane.svg"/></Link>
            <Link href={TWITTER_URL}><Image w="20px" src="/xlogo.png"/></Link>
          </Flex>
        </Flex>
        {FOOTER_LINKS.map((item) => {
          return (
            <Flex display={{base: "none", md: "flex"}} key={item.heading} flexDir="column" gap="32px" minW="140px">
              <Box fontSize="20px"> {item.heading}</Box>
              <Flex gap="16px" flexDir="column">
                {item.items.map((link) => {
                  return (
                    <Link key={link.href} href={link.href}>
                      <Box fontSize="16px" color="text.500">
                        {link.text}
                      </Box>
                    </Link>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <Flex gap={{base: "24px", md: "60px"}} align="center" opacity="0.5" pt={{base: "40px", md :"130px"}} pb="50px" flexDir={{base: "column", md: "row"}}>
        <Flex fontSize="20px">Copyright &copy; Focus 2024</Flex>
        <Flex gap="32px">
            <Link href="https://focusflr.app/privacy.pdf" target="_blank"><Box>Privacy policy</Box></Link>
        </Flex>
      </Flex>
    </CenterContainer>
  );
};

import { CheckCircleIcon, LinkIcon } from "@chakra-ui/icons";
import { Flex, Image, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FOCUS_ADDRESS } from "../../../config/blockchain";
import { TELEGRAM_URL, TWITTER_URL } from "../../../config/urls";
import { CenterContainer } from "../../shared/CenterContainer/centerContainer";

export const Topbar = () => {
  const [animate, setAnimate] = useState<boolean>(false);

  const handleCopyAddress = () => {
    if (animate == false) {
      navigator.clipboard.writeText(FOCUS_ADDRESS);
      setAnimate(true);
    }
  };

  const tryAddMetamask = async () => {


    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await (window as any).ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: FOCUS_ADDRESS, // The address that the token is at.
            symbol: "FOCUS", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: "https://focusflr.app/FOCUS.png", // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 500);
  }, [animate]);

  return (
    <Flex
      pos="fixed"
      w="100vw"
      h="40px"
      bg="black"
      top="0"
      align="center"
      zIndex={"9999"}
      borderBottom="1px solid rgba(255,255,255,0.1)"
    >
      <CenterContainer>
        <Flex justify="space-between">
          <Flex gap="32px" fontSize="14px">
            <Flex align="center" gap="8px">
              <Flex>Token:</Flex>
              <Flex
                onClick={handleCopyAddress}
                cursor="pointer"
                align="center"
                gap="4px"
              >
                <Flex>
                  {FOCUS_ADDRESS.slice(0, 4)}...{FOCUS_ADDRESS.slice(-4)}
                </Flex>
                {animate ? <CheckCircleIcon /> : <LinkIcon />}
                
              </Flex>
              <Image cursor="pointer" src="/metamask.webp" w="17px" ml="2px" onClick={tryAddMetamask}/>
            </Flex>
          </Flex>
          <Flex gap="16px" align="center">
            <Flex display={{ base: "none", md: "flex" }} fontSize="14px">
              JOIN US!
            </Flex>
            <Link href={TELEGRAM_URL}>
              <Image w="22px" src="/telegram-plane.svg" />
            </Link>
            <Link href={TWITTER_URL}>
              <Image w="16px" src="/xlogo.png" />
            </Link>
          </Flex>
        </Flex>
      </CenterContainer>
    </Flex>
  );
};

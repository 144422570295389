import { Box, Flex, Grid, Heading, Image, Text } from "@chakra-ui/react";
import { Anchor } from "../../../shared/Anchor/anchor";
import { CenterContainer } from "../../../shared/CenterContainer/centerContainer";

export const FocusToken = () => {
  return (
    <CenterContainer>
      <Anchor id="token" />
      <Grid
        my={{ base: "40px", md: "160px" }}
        gap={{ base: "80px", md: "100px" }}
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      >
        <Flex
          order={-1}
          align="center"
          justify="center"
          flexDir={{ base: "column-reverse", md: "column" }}
          gap="16px"
        >
          <Image src="/3d_coin.svg" />
          <Box
            pos="absolute"
            bg="white"
            ml="100px"
            zIndex={"1"}
            boxSize="200px"
            filter={"blur(250px)"}
          />
        </Flex>
        <Flex flexDir="column" gap="48px">
          <Heading
            fontWeight="normal"
            fontSize={{ base: "48px", md: "54px" }}
            as="h3"
          >
            <Box color="brand.500" display="inline">
              Focus
            </Box>{" "}
            Token
          </Heading>
          <Flex flexDir="column" gap="32px">
            <Text color="text.500" fontSize={{ base: "20px", md: "20px" }}>
              <Box color="white" display="inline">
                The Focus team is a trusted Infrastructure Provider
              </Box>{" "}
              on the Flare Network. For several years, we have been educating
              community on XRPL and Flare Network.
            </Text>
            <Text color="text.500" fontSize={{ base: "20px", md: "20px" }}>
              <Box color="white" display="inline">
                $FOCUS serves as a utility token in our ecosystem
              </Box>
              , providing benefits to holders and encouraging community
              engagement. It empowers our community to enhance its value and
              delivers{" "}
              <Box color="white" display="inline">
                tangible rewards
              </Box>
              , fostering collaborative development.
            </Text>
          </Flex>
        </Flex>
      </Grid>
    </CenterContainer>
  );
};

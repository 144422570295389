import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
  keyframes,
} from "@chakra-ui/react";
import { useCountdown } from "../../../../hooks/useCountdown";
import { Menu } from "../../../global/Menu/menu";
import { CenterContainer } from "../../../shared/CenterContainer/centerContainer";

const scale = keyframes`  
  0% {
    opacity: 0;
    transform: scale(1);}   
  50% { transform: scale(1.5); opacity: 1;} 
  100% { transform: scale(1); opacity: 0;} 
`;

const scale2 = keyframes`  
  0% {
    
    transform: scale(1);}   
  50% { transform: scale(1.02);} 
  100% { transform: scale(1);} 
`;

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {transform: rotate(360deg);}
`;

const spinReve = keyframes`
    0% {
      transform: rotate(360deg);
    }
    100% {transform: rotate(0deg);}
`;

export const Header = () => {
  const scaleAnimation = `${scale} infinite 5s linear`;
  const scaleAnimation2 = `${scale2} infinite 10s linear`;
  const spinQuarter = `${spin} infinite 20s linear`;
  const spinRev = `${spinReve} infinite 30s linear`;

  const { days, minutes, hours, seconds, passed } = useCountdown(
    2024,
    5,
    23,
    17
  );

  return (
    <Flex
      flexDir="column"
      bgImage={{ base: "/header-bg-mobile.png", md: "/header-background.png" }}
      w="100vw"
    >
      <CenterContainer>
        <Flex
          flexDir="column"
          py={{ base: "170px", md: "180px" }}
          pb={{ base: "0px", md: "180px" }}
          justify="center"
        >
          <Menu />

          <Grid
            templateColumns={{ base: "1fr", lg: "auto 1fr" }}
            gap="100px"
            alignItems="center"
          >
            <Flex flexDir="column" gap="50px">
              <Heading
                lineHeight="100%"
                fontWeight="400"
                fontSize={{ base: "44px", md: "80px" }}
                textAlign={{ base: "center", md: "initial" }}
                fontFamily="Inter"
              >
                We&nbsp;connect <br />{" "}
                <Box color="brand.500" display="inline">
                  the&nbsp;Community
                </Box>
              </Heading>
              <Text
                fontSize={{ base: "20px", md: "24px" }}
                color="text.500"
                textAlign={{ base: "center", md: "initial" }}
                maxW="600px"
                fontWeight="light"
                lineHeight="160%"
              >
                Focus&apos;s mission has always been to engage with the
                community through education.
              </Text>
              <Grid
                templateColumns={{ base: "1fr", md: "auto auto" }}
                maxW="400px"
                gap="16px"
              >
       
                {!passed && <Link href={"/#reveal"}>
                  <Button w="100%" role="group" minW="200px" cursor={"pointer"}>
                    Reveal Rarity
                  </Button>
                </Link>}
                {passed  && <Link href={"/staking"}>
                  <Button w="100%" role="group" minW="200px" cursor={"pointer"}>
                    Stake Pass
                  </Button>
                </Link>}
           
             
                <Link href="#pass">
                  <Button w={{ base: "100%", md: "200px" }} variant="outlined">
                    Focus Pass
                  </Button>
                </Link>
              </Grid>
            </Flex>
            <Flex
              justify="center"
              align="center"
              mt={{ base: "180px", md: "0px" }}
            >
              <Flex
                flexDir={"column"}
                justify="center"
                align="center"
                pos="relative"
              >
                <Box
                  left="0"
                  right="0"
                  pos={{ base: "unset", md: "absolute" }}
                  pl="4px"
                  top="0"
                  bottom="0"
                  margin="auto"
                  zIndex={5}
                  boxSize={{ base: "56vw", md: "200px" }}
                >
                  <Box pos="relative" boxSize={{ base: "56vw", md: "200px" }}>
                    <Image
                      pos="absolute"
                      left="0"
                      right="0"
                      top="0"
                      bottom="0"
                      margin="auto"
                      w="60%"
                      animation={spinQuarter}
                      src="/animated/spin-quarter.svg"
                    />
                    <Image
                      pos="absolute"
                      left="0"
                      right="0"
                      top="0"
                      bottom="0"
                      margin="auto"
                      w="100%"
                      animation={spinRev}
                      src="/animated/spin-dtted.svg"
                    />
                    <Image
                      pos="absolute"
                      left="0"
                      right="0"
                      top="0"
                      bottom="0"
                      margin="auto"
                      pr="4%"
                      w="70%"
                      src="/animated/center.png"
                    />
                  </Box>
                </Box>
                <Image
                  src="/animated/ticket-animated.png"
                  pos={{ base: "absolute", md: "initial" }}
                  alt="focus pass red"
                  maxW={{ base: "90vw", md: "380px" }}
                  animation={scaleAnimation2}
                  zIndex="2"
                />
              </Flex>
              <Box
                animation={scaleAnimation}
                pos="absolute"
                bg="brand.500"
                zIndex={"1"}
                boxSize="150px"
                filter={"blur(100px)"}
              />
            </Flex>
          </Grid>
        </Flex>
      </CenterContainer>
    </Flex>
  );
};
